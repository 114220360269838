<template>
	<div>
		<span
			class="spinner-border spinner-border-sm me-8px"
			role="status"
			aria-hidden="true"
		></span>
		Loading...
	</div>
</template>
<script>
import { inject, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
	setup(props, context) {
		const auth = inject("auth");
		const $api = inject("$api");
		const _ = inject("_");
		const router = useRouter();
		const route = useRoute();
		const url = decodeURI(route.query.url);
		const login = () => {
			$api.auth.magic
				.login(url)
				.then(function (response) {
					auth.login(response.data);
				})
				.catch(function (errors) {
					router.push({
						name: "auth.magic.email",
						query: { error: 401 },
					});
				});
		};
		onMounted(() => {
			login();
		});
		return {
			login,
		};
	},
};
</script>
